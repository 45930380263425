.reviewsContainer {
  max-width: 90%; /* Cambiar el tamaño máximo para adaptarse mejor al ancho de la pantalla */
  margin: 30px auto; /* Espacio relativo debajo del navbar */
  padding: 2rem;
  background: rgba(0, 0, 0, 0.7); /* Fondo oscuro translúcido */
  border-radius: 10px;
  color: #F5F3F4; /* Blanco perla */
  width: 100%;
}

.reviewsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px; /* Espacio entre las tarjetas y el formulario */
  gap: 2rem; /* Espacio entre las tarjetas */
}

.floatingButton {
  position: fixed;
  bottom: 80px; /* Espacio relativo desde el footer */
  right: 30px; /* Espacio relativo desde el lado derecho */
  background: #FFD700; /* Dorado */
  color: #000;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: background 0.3s;
}

.floatingButton:hover {
  background: #FFC107;
}

@media (max-width: 60em) {
  .reviewsContainer {
    padding: 1.5rem; /* Reducir padding para pantallas medianas */
    margin: 20px auto; /* Ajustar margen para pantallas medianas */
  }

  .reviewsList {
    gap: 1.5rem; /* Reducir espacio entre las tarjetas */
  }

  .floatingButton {
    bottom: 70px; /* Ajustar espacio relativo desde el footer para pantallas medianas */
    right: 20px; /* Ajustar espacio relativo desde el lado derecho para pantallas medianas */
    width: 50px; /* Reducir tamaño del botón */
    height: 50px; /* Reducir tamaño del botón */
    font-size: 20px; /* Reducir tamaño de la fuente */
  }
}

@media (max-width: 40em) {
  .reviewsContainer {
    padding: 1rem; /* Reducir padding para pantallas pequeñas */
    margin: 10px auto; /* Ajustar margen para pantallas pequeñas */
  }

  .reviewsList {
    gap: 1rem; /* Reducir espacio entre las tarjetas */
  }

  .floatingButton {
    bottom: 60px; /* Ajustar espacio relativo desde el footer para pantallas pequeñas */
    right: 10px; /* Ajustar espacio relativo desde el lado derecho para pantallas pequeñas */
    width: 40px; /* Reducir tamaño del botón */
    height: 40px; /* Reducir tamaño del botón */
    font-size: 18px; /* Reducir tamaño de la fuente */
  }
}
