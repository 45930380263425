.contactSection {
    padding: 2rem;
    color: #F5F3F4;
  }
  
  .contactTitle {
    text-align: center;
    color: #FFD700; /* Dorado */
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .contactForm {
    max-width: 600px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.1); /* Fondo oscuro translúcido */
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .contactFormGroup {
    margin-bottom: 1.5rem;
  }
  
  .contactFormGroup label {
    display: block;
    margin-bottom: 0.5rem;
    color: #F5F3F4;
  }
  
  .contactFormGroup input,
  .contactFormGroup textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #333;
    border-radius: 5px;
    background: #1e1f26; /* Fondo oscuro */
    color: #F5F3F4;
    font-size: 1rem;
  }
  
  .contactFormGroup input:focus,
  .contactFormGroup textarea:focus {
    border-color: #FFD700; /* Resalta en dorado al enfocar */
    outline: none;
  }
  
  .contactFormGroup textarea {
    resize: vertical;
    height: 150px;
  }
  
  .contactForm button {
    display: block;
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 5px;
    background: #FFD700; /* Fondo dorado */
    color: #000; /* Texto negro */
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .contactForm button:hover {
    background: #f0c14b; /* Un poco más oscuro al pasar el ratón */
  }
  
  .contactSection h2 {
    text-align: center;
    color: #FFD700;
  }
  
  @media (max-width: 60em) {
    .contactSection {
      padding: 1.5rem; /* Reducir padding para pantallas medianas */
    }
  
    .contactTitle {
      font-size: 1.8rem; /* Reducir tamaño del texto */
    }
  
    .contactForm {
      padding: 1.5rem; /* Reducir padding para el formulario */
    }
  
    .contactFormGroup label {
      font-size: 0.9rem; /* Reducir tamaño del texto */
    }
  
    .contactFormGroup input,
    .contactFormGroup textarea {
      padding: 0.65rem; /* Reducir padding de los inputs */
    }
  
    .contactForm button {
      padding: 0.65rem; /* Reducir padding del botón */
    }
  }
  
  @media (max-width: 40em) {
    .contactSection {
      padding: 1rem; /* Reducir padding para pantallas pequeñas */
    }
  
    .contactTitle {
      font-size: 1.5rem; /* Reducir tamaño del texto */
    }
  
    .contactForm {
      padding: 1rem; /* Reducir padding para el formulario */
    }
  
    .contactFormGroup label {
      font-size: 0.8rem; /* Reducir tamaño del texto */
    }
  
    .contactFormGroup input,
    .contactFormGroup textarea {
      padding: 0.55rem; /* Reducir padding de los inputs */
    }
  
    .contactForm button {
      padding: 0.55rem; /* Reducir padding del botón */
    }
  }
  