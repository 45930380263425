.projectsContainer {
  padding: 2rem;
  margin-top: 2rem;
  background-color: #000; /* Color de fondo */
  width: 100%;
}

.projectCardContainer {
  display: flex;
  justify-content: center;
}

.projectCard {
  background-color: #292c34;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: #dce4ff;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto; /* Asegura que las tarjetas estén centradas */
}

.projectImage {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.projectInfo {
  padding: 1rem;
}

.projectName {
  color: #FFD700;
  font-size: 1.8rem; 
  margin-bottom: 0.5rem;
}

.projectDescription, .projectRole, .projectResponsibilitiesTitle, .projectProcessTitle, .projectTechnologiesTitle {
  font-size: 1.2rem; 
  margin-bottom: 0.5rem;
}

.projectResponsibilitiesTitle, .projectProcessTitle, .projectTechnologiesTitle {
  color: #FFD700;
  font-weight: bold;
  margin-top: 1rem;
}

.projectResponsibility, .projectProcess {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.technologies {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;
}

.technologyPill {
  background-color: #FFD700;
  color: #000;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 1rem;
}

.slick-prev, .slick-next {
  z-index: 1;
  color: #FFD700;
}

.slick-prev:hover, .slick-next:hover {
  color: #d4af37;
}

.slick-dots li button:before {
  color: #FFD700;
}

.slick-dots li.slick-active button:before {
  color: #d4af37;
}

@media (max-width: 60em) {
  .projectCard {
    max-width: 600px; /* Ajuste para pantallas medianas */
    padding: 1rem;
  }

  .projectName {
    font-size: 1.5rem; /* Reducir tamaño del texto */
  }

  .projectDescription, .projectRole, .projectResponsibilitiesTitle, .projectProcessTitle, .projectTechnologiesTitle {
    font-size: 1rem; /* Reducir tamaño del texto */
  }

  .projectResponsibility, .projectProcess {
    font-size: 0.9rem; /* Reducir tamaño del texto */
  }

  .technologyPill {
    font-size: 0.9rem; /* Reducir tamaño del texto */
  }
}

@media (max-width: 40em) {
  .projectCard {
    max-width: 100%; /* Ajuste para pantallas pequeñas */
    padding: 0.5rem;
  }

  .projectName {
    font-size: 1.2rem; /* Reducir tamaño del texto */
  }

  .projectDescription, .projectRole, .projectResponsibilitiesTitle, .projectProcessTitle, .projectTechnologiesTitle {
    font-size: 0.9rem; /* Reducir tamaño del texto */
  }

  .projectResponsibility, .projectProcess {
    font-size: 0.8rem; /* Reducir tamaño del texto */
  }

  .technologyPill {
    font-size: 0.8rem; /* Reducir tamaño del texto */
  }
}
