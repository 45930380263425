h3.span {
    font-size: 3vw; /* Tamaño base reducido en un 25% */
    letter-spacing: 0.7em;
    color: #F5F3F4; /* Blanco Perla */
    z-index: 4;
}

.loaderContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;
    position: relative;
    background-color: #000; /* Morado Oscuro */
    text-align: center;
    padding: 0 2em;
}

.loader span {
    color: #faebd7;
    text-shadow: 0 0 0 #5A5A66;
    animation: loading 1.5s ease-in-out infinite alternate;
}

@keyframes loading {
    to {
        text-shadow: 15px 0 30px #FFD700;
        color: #FFD700;
    }
}

.loader span {
    animation: loading 1.5s ease-in-out infinite alternate;
    animation-delay: calc(0.1s * var(--i));
}

.loader span:nth-child(1) { --i: 1; }
.loader span:nth-child(2) { --i: 2; }
.loader span:nth-child(3) { --i: 3; }
.loader span:nth-child(4) { --i: 4; }
.loader span:nth-child(5) { --i: 5; }
.loader span:nth-child(6) { --i: 6; }
.loader span:nth-child(7) { --i: 7; }
.loader span:nth-child(8) { --i: 8; }
.loader span:nth-child(9) { --i: 9; }
.loader span:nth-child(10) { --i: 10; }
.loader span:nth-child(11) { --i: 11; }
.loader span:nth-child(12) { --i: 12; }
.loader span:nth-child(13) { --i: 13; }
.loader span:nth-child(14) { --i: 14; }
.loader span:nth-child(15) { --i: 15; }
.loader span:nth-child(16) { --i: 16; }
.loader span:nth-child(17) { --i: 17; }
.loader span:nth-child(18) { --i: 18; }
.loader span:nth-child(19) { --i: 19; }
.loader span:nth-child(20) { --i: 20; }
.loader span:nth-child(21) { --i: 21; }
.loader span:nth-child(22) { --i: 22; }

/* Responsive adjustments */
@media (max-width: 767px) {
  h3.span {
    font-size: 4vw; /* Aumentar el tamaño de las letras en dispositivos móviles */
  }
}
