.reviewCard {
  background: #292c34;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 2px 1px 5px #FFD700, 0px 0px 5px rgba(0, 0, 0, 0.1); /* Añade sombra con efecto blur */
  margin-bottom: 1rem;
  color: #dce4ff;
  flex: 1 1 calc(40% - 2rem); /* Para cubrir un 40% del ancho de la página */
  max-width: calc(40% - 2rem); /* Para cubrir un 40% del ancho de la página */
  margin: 1rem; /* Añade espacio alrededor de las tarjetas */
  word-break: break-word; /* Permite que las palabras largas se rompan para ajustarse al contenedor */
}

.reviewCard h3 {
  margin: 0 0 0.5rem 0;
  color: #FFD700; /* Dorado */
  font-size: 1.5rem; /* Tamaño de fuente más grande */
  font-weight: bold; /* Negrita */
}

.reviewCard .profession {
  margin: 0 0 1rem 0;
  color: #F5F3F4; /* Blanco perla */
  font-size: 1.25rem; /* Tamaño de fuente mediano */
}

.reviewCard .email {
  margin: 0 0 1rem 0;
  color: #B0B0B0; /* Color menos prominente */
  font-size: 1rem; /* Tamaño de fuente más pequeño */
}

.reviewCard .review {
  margin: 0 0 1rem 0;
  color: #F5F3F4; /* Blanco perla */
  font-size: 1rem; /* Tamaño de fuente normal */
}

.reviewsList {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem; /* Aumenta el espacio entre las tarjetas */
  justify-content: center;
}

@media (max-width: 60em) {
  .reviewCard {
    flex: 1 1 calc(45% - 1rem); /* Ajusta para pantallas medianas */
    max-width: calc(45% - 1rem); /* Ajusta para pantallas medianas */
  }

  .reviewCard h3 {
    font-size: 1.4rem; /* Reducir tamaño del texto */
  }

  .reviewCard .profession {
    font-size: 1.1rem; /* Reducir tamaño del texto */
  }

  .reviewCard .email {
    font-size: 0.9rem; /* Reducir tamaño del texto */
  }

  .reviewCard .review {
    font-size: 0.9rem; /* Reducir tamaño del texto */
  }
}

@media (max-width: 40em) {
  .reviewCard {
    flex: 1 1 100%; /* Ajusta para pantallas pequeñas */
    max-width: 100%; /* Ajusta para pantallas pequeñas */
  }

  .reviewCard h3 {
    font-size: 1.2rem; /* Reducir tamaño del texto */
  }

  .reviewCard .profession {
    font-size: 1rem; /* Reducir tamaño del texto */
  }

  .reviewCard .email {
    font-size: 0.8rem; /* Reducir tamaño del texto */
  }

  .reviewCard .review {
    font-size: 0.8rem; /* Reducir tamaño del texto */
  }
}
