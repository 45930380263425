.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background: rgba(30, 31, 38, 0.9); /* Fondo oscuro translúcido */
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #F5F3F4; /* Blanco perla */
    max-width: 800px; /* Aumenta el ancho máximo del formulario */
    width: 100%;
  }
  
  .reviewForm h2 {
    margin-bottom: 1rem;
    color: #FFD700; /* Dorado */
  }
  
  .formGroup {
    margin-bottom: 1rem;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 0.5rem;
    color: #F5F3F4; /* Blanco perla */
  }
  
  .formGroup input, .formGroup textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #1e1f26;
    color: #F5F3F4; /* Blanco perla */
  }
  
  .formGroup textarea {
    height: 150px; /* Aumenta el tamaño del área de texto para la reseña */
  }
  
  .reviewForm button {
    background: #FFD700; /* Dorado */
    color: #000;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .reviewForm button:hover {
    background: #FFC107;
  }
  
  .formGroupInline {
    display: flex;
    gap: 1rem;
  }
  
  .formGroupInline .formGroup {
    flex: 1;
  }
  