*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #000;
}

@media (max-width: 60em) {
  body {
    padding: 3rem;
  }
}

.empty {
  color: transparent !important; /* Hace que el fondo de la estrella sea transparente */
  border: 1px solid gold !important; /* Agrega un borde dorado para mostrar el contorno de la estrella */
}

.grid {
  display: grid;
  gap: 3rem;
  grid-template-columns: repeat(3, minmax(20rem, 1fr)); /* Ajuste para que siempre haya 3 tarjetas en fila */
  justify-content: center; /* Centra las tarjetas en la cuadrícula si hay espacio adicional */
  align-items: start; /* Alinea las tarjetas al inicio del contenedor en el eje vertical */
}

@media (max-width: 60em) {
  .grid {
    grid-template-columns: repeat(2, 1fr); /* 2 tarjetas por fila en pantallas medianas */
    gap: 2rem;
  }
}

@media (max-width: 40em) {
  .grid {
    grid-template-columns: 1fr; /* 1 tarjeta por fila en pantallas pequeñas */
    gap: 1rem;
  }
}

.grid .card {
  display: flex;
  flex-direction: column;
  background-color: #292c34;
  border-radius: 0.4rem;
  overflow: hidden;
  box-shadow: 0 0.1rem 0.2rem #FFD700;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.grid .card:hover {
  transform: translateY(-0.5%);
  box-shadow: 0 2rem 4rem rgba(0, 0, 0, 0.2);
}

.grid .card__img {
  display: block;
  width: 100%;
  aspect-ratio: 16 / 9; /* Proporción de aspecto para mantener la consistencia */
  object-fit: cover;
}

.grid .card__content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto; /* Altura automática para ajustarse al contenido */
  transition: transform 0.3s ease-in-out;
}

.grid .card__header {
  font-size: 1.5rem; /* Reducir el tamaño del encabezado */
  font-weight: 500;
  color: #FFD700;
}

.grid .card__text {
  font-size: 1.2rem; /* Reducir el tamaño del texto */
  color: #dce4ff;
  margin-bottom: 1.5rem; /* Reducir el margen inferior */
}

.grid .card__btn span {
  margin-left: 1rem;
  transition: 0.2s;
}

.grid .card__btn:hover, .grid .card__btn:active {
  background-color: #dce4ff;
}

.grid .card__btn:hover span, .grid .card__btn:active span {
  margin-left: 1.5rem;
}

.interest-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  position: relative;
  text-align: center;
  background: rgba(0, 0, 0, 0.5); /* Fondo oscuro con transparencia */
  color: #F5F3F4; /* Blanco Perla */
  padding: 6rem 2rem;
  margin-top: 20px;
}

.interest-section h2 {
  text-align: center;
  margin-bottom: 3rem;
  font-size: 2rem; /* Reducir el tamaño del título de la sección */
}

.skills-section {
  padding: 4rem 2rem;
  background-color: #000; /* Fondo oscuro para la sección */
  color: #fff;
  text-align: center;
}

.skills-grid {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr); /* 4 tarjetas por fila */
  padding: 2rem;
  max-width: 1200px; /* Limita el ancho máximo de la cuadrícula */
  margin: auto; /* Centra la cuadrícula */
}

@media (max-width: 60em) {
  .skills-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 tarjetas por fila en pantallas medianas */
    gap: 2rem;
  }
}

@media (max-width: 40em) {
  .skills-grid {
    grid-template-columns: 1fr; /* 1 tarjeta por fila en pantallas pequeñas */
    gap: 1rem;
  }
}

.skill-card {
  background-color: #292c34; /* Color de fondo más suave */
  padding: 1rem; /* Reducir el padding */
  border-radius: 10px; /* Bordes redondeados */
  text-align: center;
  box-shadow: 0 2px 5px #FFD700; /* Sombra más prominente */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transición suave */
}

.skill-card:hover, .card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.skill-card:hover .fa-star {
  animation: pulseStar 0.75s infinite alternate;
}

.skill-icon {
  font-size: 2rem; /* Reducir el tamaño del icono */
  color: #FFD700;
  margin-bottom: 10px; /* Reducir el margen inferior */
}

.skill-name {
  font-size: 1.2rem; /* Reducir el tamaño del texto */
  color: #fff;
  margin-bottom: 5px; /* Reducir el margen inferior */
}

.star {
  color: gold;
}

.stars {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stars .fa-star {
  color: #FFD700; /* Estrellas doradas */
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Animación para las estrellas */
@keyframes pulseStar {
  0% { transform: scale(1); color: #FFD700; }
  100% { transform: scale(1.2); color: #ffffff; }
}

.section-header {
  text-align: center;
  margin-bottom: 3rem;
  position: relative;
  font-size: 2rem; /* Reducir el tamaño del texto */
  color: #FFD700; /* Color dorado para hacerlos resaltar */
  font-weight: bold;
}

.section-header::after {
  content: '';
  display: block;
  width: 50px; /* Ajusta el ancho de la línea según prefieras */
  height: 2px; /* Ajusta el grosor de la línea */
  background-color: #FFD700; /* Color dorado para la línea debajo del texto */
  margin: 20px auto 0; /* Alineación y espaciado de la línea */
}

a, .skill-card, .card {
  transition: transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}

.card__header, .skill-name {
  color: #FFD700;
  text-shadow: 0 2px 4px rgba(0,0,0,0.5);
}

/* Hacer que los headers sean más impresionantes */
.section-header {
  font-size: 2rem; /* Reducir el tamaño del texto */
  color: #FFD700;
  background: -webkit-linear-gradient(45deg, #FFD700, #ffffff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 5s linear infinite;
}

@keyframes shine {
  from { filter: hue-rotate(0deg); }
  to { filter: hue-rotate(360deg); }
}

.curriculum-section {
  padding: 4rem 2rem;
  background-color: #000; /* Fondo oscuro para contraste */
  color: #fff;
  text-align: center;
}

.curriculum-preview {
  margin-top: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.curriculum-thumbnail {
  width: 100%; /* Ajusta según necesidad, podría ser un valor fijo en px */
  max-width: 600px; /* Límite de ancho para la imagen */
  border-radius: 5px; /* Bordes redondeados para estética */
  box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Sombra para profundidad */
  transition: transform 0.3s ease;
}

.curriculum-thumbnail:hover {
  transform: scale(1.05); /* Efecto zoom al pasar el mouse */
}

.btn-download {
  margin-top: 2rem;
  padding: 0.8rem 2rem;
  font-size: 1.2rem; /* Reducir el tamaño del texto */
  color: #000;
  background-color: #FFD700; /* Botón destacado */
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.btn-download:hover {
  background-color: #e6c300; /* Oscurece en hover */
}

/* Nuevos estilos para la sección de Premios y Reconocimientos */
.awards-section {
  padding: 2rem;
  background-color: #000; /* Fondo negro */
  color: #dce4ff; /* Texto claro */
}

.awards-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.award-card {
  background-color: #292c34;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  flex: 1 1 calc(33.333% - 2rem); /* Tarjetas responsivas */
  max-width: 300px;
}

@media (max-width: 60em) {
  .awards-grid {
    flex-direction: column; /* Cambiar a una columna en pantallas pequeñas */
  }

  .award-card {
    flex: none;
    width: 100%;
  }
}

.award-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.award-icon {
  font-size: 2rem; /* Reducir el tamaño del icono */
  color: #FFD700;
  margin-bottom: 1rem;
}

.award-title {
  font-size: 1.2rem; /* Reducir el tamaño del texto */
  color: #FFD700;
  margin-bottom: 0.5rem;
}

.award-description {
  font-size: 1rem; /* Mantener el tamaño del texto */
  color: #dce4ff;
}

.award-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-top: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
