/* src/css/Post.module.css */
.postCard {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    margin: 2em 0; /* Aumentar margen superior e inferior */
    padding: 1em; /* Aumentar padding */
    color: #F5F3F4;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
  }
  
  .postCard:hover {
    transform: scale(1.02);
  }
  
  .postCardImage {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .postCardContent {
    padding: 1.5em;
  }
  
  .postCardTitle {
    font-size: 2rem;
    color: #FFD700;
    font-weight: bold;
    margin: 1em 0;
  }
  
  .postCardDate, .postCardAuthor {
    font-size: 1rem;
    color: #F5F3F4;
    margin: 0.5em 0;
  }
  
  .postCardDescription {
    font-size: 1.25rem;
    color: #F5F3F4;
    margin: 1em 0;
    line-height: 1.5;
  }
  
  @media (max-width: 60em) {
    .postCard {
      margin: 1.5em 0; /* Ajustar margen para pantallas medianas */
      padding: 0.8em; /* Ajustar padding para pantallas medianas */
    }
  
    .postCardImage {
      height: 180px; /* Ajustar altura de la imagen para pantallas medianas */
    }
  
    .postCardTitle {
      font-size: 1.8rem; /* Reducir tamaño del texto */
      margin: 0.8em 0;
    }
  
    .postCardDate, .postCardAuthor {
      font-size: 0.9rem; /* Reducir tamaño del texto */
    }
  
    .postCardDescription {
      font-size: 1.1rem; /* Reducir tamaño del texto */
      margin: 0.8em 0;
    }
  }
  
  @media (max-width: 40em) {
    .postCard {
      margin: 1em 0; /* Ajustar margen para pantallas pequeñas */
      padding: 0.5em; /* Ajustar padding para pantallas pequeñas */
    }
  
    .postCardImage {
      height: 150px; /* Ajustar altura de la imagen para pantallas pequeñas */
    }
  
    .postCardTitle {
      font-size: 1.5rem; /* Reducir tamaño del texto */
      margin: 0.5em 0;
    }
  
    .postCardDate, .postCardAuthor {
      font-size: 0.8rem; /* Reducir tamaño del texto */
    }
  
    .postCardDescription {
      font-size: 1rem; /* Reducir tamaño del texto */
      margin: 0.5em 0;
    }
  }
  