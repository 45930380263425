.footer {
  width: 100%;
  padding: 10px 0;
  background-color: #1e1f26; /* Color oscuro para el fondo del footer */
  color: #fff;
  text-align: center;
  position: fixed; /* Posición fija para asegurarse de que siempre esté en la parte inferior */
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000; /* Asegura que el footer esté siempre por encima de otros elementos */
}

.socialLinks {
  display: flex;
  justify-content: center;
  list-style: none;
}

.socialLink {
  margin: 0 10px;
  color: #fff; /* Color blanco para los iconos */
  font-size: 1.5rem; /* Tamaño de los iconos */
  transition: color 0.3s ease;
}

.socialLink:hover, .socialLink:focus {
  color: #FFD700; /* Cambia el color al pasar el ratón o al enfocar */
}

/* Asegúrate de que el tamaño de los iconos sea responsive y se vea bien en dispositivos móviles */
@media screen and (max-width: 768px) {
  .socialLink {
    font-size: 1.2rem;
  }
}
