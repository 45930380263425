html {
  box-sizing: border-box;
  --bgColorMenu: #1d1d27;
  --duration: 0.7s;
  --menuTextColor: #F5F3F4;
  --menuHoverColor: #FFD700;
  --menuActiveColor: #FF8C00;
}

*, *::before, *::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: flex-start;
  color: var(--menuTextColor);
  -webkit-tap-highlight-color: transparent;
  transition: background-color var(--duration);
}

.navbar {
  width: 100%;
  background-color: var(--bgColorMenu);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em 2em;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  max-height: 40px;
  width: auto;
  margin-right: 20px;
}

.menuToggle {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hamburger {
  width: 25px;
  height: 3px;
  background-color: var(--menuTextColor);
  margin: 3px 0;
  transition: transform var(--duration), background-color var(--duration);
}

.menu {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  font-size: 1em;
  background-color: var(--bgColorMenu);
}

.menuItem {
  cursor: pointer;
  margin-left: 20px;
  color: var(--menuTextColor);
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color var(--duration), color var(--duration), transform var(--duration);
  text-decoration: none;
}

.menuItem:hover, .menuItem.active {
  background-color: var(--menuHoverColor);
  color: #000;
  transform: scale(1.05);
}

.languageSwitcher {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.languageSwitcher button {
  background-color: var(--bgColorMenu);
  color: var(--menuTextColor);
  border: 2px solid var(--menuHoverColor);
  font-size: 1em;
  padding: 10px 20px;
  margin-left: 10px;
  border-radius: 5px;
  transition: background-color var(--duration), color var(--duration), transform var(--duration);
  outline: none;
  cursor: pointer;
}

.languageSwitcher button:hover {
  background-color: var(--menuHoverColor);
  color: #000;
  transform: translateY(-3px);
}

.languageSwitcher button:active {
  transform: translateY(1px);
}

.languageSwitcher button:focus {
  border-color: var(--menuActiveColor);
}

@media screen and (max-width: 50em) {
  .menu {
    flex-direction: column;
    align-items: center;
    max-height: 0;
    overflow: hidden;
    transition: max-height var(--duration) ease-in-out;
    width: 100%; /* Asegurar que el menú ocupe todo el ancho del contenedor */
    position: absolute;
    top: 60px; /* Ajustar según la altura del navbar */
  }
  .menu.active {
    max-height: 500px; /* Ajustar según la cantidad de elementos del menú */
  }
  .menuItem {
    margin: 10px 0;
    width: 100%; /* Asegurar que los elementos del menú ocupen todo el ancho del contenedor */
    text-align: center; /* Centrar el texto de los elementos del menú */
    margin-top: 15px; /* Añadir margen superior de 15px */
  }
  .menuToggle {
    display: flex;
    order: 2; /* Mover el botón de hamburguesa al final del contenedor */
  }
  .languageSwitcher {
    flex-direction: column; /* Cambiar la dirección para que los botones se alineen verticalmente */
    margin-top: 15px; /* Añadir margen superior de 15px */
    margin-left: 0; /* Asegurar que el margen izquierdo no cause problemas de alineación */
    width: 100%; /* Asegurar que los botones ocupen todo el ancho del contenedor */
    text-align: center; /* Centrar los botones */
  }
  .languageSwitcher button {
    margin-left: 0; /* Eliminar el margen izquierdo */
    margin-top: 10px; /* Añadir un margen superior entre los botones */
    width: 80%; /* Asegurar que los botones no ocupen todo el ancho pero se vean bien alineados */
  }
}
