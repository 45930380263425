.contactContainer {
    padding: 2rem;
    background: rgba(0, 0, 0, 0.7); /* Fondo oscuro translúcido */
    border-radius: 10px;
    max-width: 800px;
    margin: 0 auto;
    color: #F5F3F4; /* Blanco perla */
  }
  
  .contactContainer h1 {
    text-align: center;
    margin-bottom: 1rem;
    color: #FFD700; /* Color dorado */
  }
  
  .contactForm {
    display: flex;
    flex-direction: column;
  }
  
  .formGroup {
    margin-bottom: 1rem;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .formGroup input,
  .formGroup textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #333; /* Fondo oscuro para los inputs */
    color: #F5F3F4; /* Texto blanco perla */
  }
  
  .formGroup input:focus,
  .formGroup textarea:focus {
    border-color: #FFD700; /* Borde dorado al enfocar */
    outline: none;
  }
  
  textarea {
    resize: vertical;
  }
  
  .submitButton {
    background-color: #FFD700; /* Botón dorado */
    color: #000; /* Texto negro */
    padding: 0.75rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submitButton:hover {
    background-color: #e5c100; /* Color ligeramente más oscuro al pasar el ratón */
  }
  
  @media (max-width: 60em) {
    .contactContainer {
      padding: 1.5rem; /* Reducir padding para pantallas medianas */
    }
  
    .formGroup label {
      font-size: 0.9rem; /* Reducir tamaño del texto */
    }
  
    .formGroup input,
    .formGroup textarea {
      padding: 0.65rem; /* Reducir padding de los inputs */
    }
  
    .submitButton {
      padding: 0.65rem; /* Reducir padding del botón */
    }
  }
  
  @media (max-width: 40em) {
    .contactContainer {
      padding: 1rem; /* Reducir padding para pantallas pequeñas */
    }
  
    .formGroup label {
      font-size: 0.8rem; /* Reducir tamaño del texto */
    }
  
    .formGroup input,
    .formGroup textarea {
      padding: 0.55rem; /* Reducir padding de los inputs */
    }
  
    .submitButton {
      padding: 0.55rem; /* Reducir padding del botón */
    }
  }
  