/* src/css/PostDetail.module.css */
.postDetail {
    background: rgba(255, 255, 255, 0.1);
    padding: 2vw;
    border-radius: 15px;
    text-align: left;
    margin: 2em auto; /* Centrar y dar margen superior e inferior */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: #F5F3F4;
    max-width: 900px; /* Limitar el ancho máximo */
    padding: 2.5em; /* Añadir padding para el contenedor */

  }
  
  .postDetailImage {
    width: 100%;
    border-radius: 15px;
    max-height: 400px;
    object-fit: cover;
    margin-bottom: 1em;
  }
  
  .postDetailTitle {
    font-size: 2.5rem;
    color: #FFD700;
    font-weight: bold;
    margin: 1em 0;
  }
  
  .postDetailDate, .postDetailAuthor {
    font-size: 1rem;
    color: #F5F3F4;
    margin: 0.5em 0;
  }
  
  .postDetailDescription {
    font-size: 1.5rem;
    color: #F5F3F4;
    margin: 1em 0;
    line-height: 1.5;
  }
  
  .postDetailContent p {
    font-size: 1.25rem;
    color: #F5F3F4;
    margin: 1em 0;
    line-height: 1.5;
  }
  
  .postDetailContent {
    margin-top: 1em;
  }
  