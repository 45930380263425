* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #000;
  color: #F5F3F4;
  font-family: "Josefin Sans", sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root {
  flex: 1;
}

.home {
  position: relative;
  overflow: hidden;
  flex: 1;
}

.homeBackground {
  position: absolute;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  background: #000;
}

.homeContent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1em;
  z-index: 1;
  background: rgba(0, 0, 0, 0.7);
}

.homeLogo {
  max-height: 20vh;
  max-width: 150px;
  margin-right: 2vw;
}

.homeProfileContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  padding: 3vw;
  border-radius: 15px;
  text-align: center;
  width: 95%; /* Aumentar el ancho del contenedor */
  max-width: 1080px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 2em;
}

.homeProfilePic {
  width: 40vw;
  max-width: 150px;
  height: auto;
  border-radius: 15%;
  object-fit: cover;
  margin-top: 1em;
}

.homeAboutMeText {
  flex: 1;
  color: #F5F3F4;
  text-align: justify;
  margin-top: 1em;
}

.homeAboutMeText p {
  font-size: 1rem; /* Reducir el tamaño de las letras */
  color: #F5F3F4;
  margin: 0;
  letter-spacing: 0.1em;
  line-height: 1.5;
}

.homeMyName {
  font-size: 1.5rem; /* Reducir el tamaño de las letras */
  color: #FFD700;
  font-weight: bold;
  display: block;
  margin-bottom: 1rem;
}

.homeAnimatedTextContainer {
  margin-top: 2em;
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Responsive adjustments */
@media (min-width: 800px) {
  .homeProfileContainer {
    flex-direction: row;
    text-align: left;
    
  }

  .homeProfilePic {
    width: 20vw;
    max-width: 150px;
    margin-left: 2vw;
  }

  .homeLogo {
    margin-right: 2vw;
  }
}

@media (min-width: 1200px) {
  .homeProfileContainer {
    padding: 2em;
  }
}
